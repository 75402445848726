import React from 'react';
import { Helmet } from 'react-helmet';
import { StaticImage } from "gatsby-plugin-image";
import { css } from '@emotion/react';
import Cover from '../content/img/blog-cover.png';
import { 
  FaReact,
  FaVuejs
} from 'react-icons/fa';
import { 
  SiMicrosoftazure,
  SiAndroid,
  SiSwift,
  SiDotnet,
  SiXamarin,
  SiMicrosoftsqlserver,
  SiAzuredevops,
  SiGithub
} from 'react-icons/si';

import { 
  GrServices
} from 'react-icons/gr';

import { Footer } from '../components/Footer';
import SiteNav from '../components/header/SiteNav';
import { PostFullContent } from '../components/PostContent';
import { Wrapper } from '../components/Wrapper';
import IndexLayout from '../layouts';
import {
  inner,
  outer,
  SiteArchiveHeader,
  SiteHeader,
  SiteMain,
  SiteNavMain,
} from '../styles/shared';
import { NoImage, PostFull, PostFullHeader, PostFullTitle } from '../templates/post';
import { colors } from '../styles/colors';

const PageTemplate = css`
  .site-main {
    margin-top: 64px;
    padding-bottom: 4vw;
    background: #fff;
  }

  @media (prefers-color-scheme: dark) {
    .site-main {
      /* background: var(--darkmode); */
      background: ${colors.darkmode};
    }
  }
`;

const About: React.FC = () => (
  <IndexLayout>
    <Helmet>
      <title>About</title>
    </Helmet>
    <Wrapper css={PageTemplate}>
      <header className="site-archive-header no-image" css={[SiteHeader, SiteArchiveHeader]}>
        <div css={[outer, SiteNavMain]}
                    style={{
                      backgroundImage: `url('${Cover}')`,
                    }}
        >
          <div css={inner}>
            <SiteNav/>
          </div>
        </div>
      </header>
      <main id="site-main" className="site-main" css={[SiteMain, outer]}>
        <div css={inner}>
          <article className="post page" css={[PostFull, NoImage]}>
            <PostFullHeader className="post-full-header">
              <PostFullTitle className="post-full-title">About</PostFullTitle>
            </PostFullHeader>

            <PostFullContent className="post-full-content">
              <div className="post-content">
                <StaticImage
                    className="bio-avatar"
                    layout="fixed"
                    formats={["auto", "webp", "avif"]}
                    src="../content/img/profilev2.png"
                    width={300}
                    height={300}
                    quality={100}
                    alt="Profile picture"
                  />
                <p style={{marginTop: 15}}>
                  👋 Hello there! I'm Luis Sandoval, a passionate software developer hailing from Cartago, Costa Rica. My journey in the world of coding has been an exciting one, driven by a relentless curiosity to learn and evolve every day. I proudly identify as a self-taught individual and a lifelong learner, which is precisely what drew me to this dynamic career. In a realm where technology evolves by the minute and every second counts, I find myself invigorated by the perpetual learning process.
                </p>
                <h2>Experience</h2>
                <ul>
                  <li>Gestión en Tecnología e Información GTI - San José, Costa Rica
                    <ul>
                      <li>Senior Software Developer (May. 2022 - Present)</li>
                      <li>Mid Software Developer (Feb. 2021 - May. 2022)</li>
                      <li>Junior Software Developer (Dic. 2018 - Feb. 2021)</li>
                      <li>Software Developer Internship (Sept. 2018 - Dic. 2018)</li>
                    </ul>
                  </li>
                </ul>
                <h2>Skills</h2>
                <ul>
                  <li>C#, .NET Core, .NET Framework, Blazor, .NET Mvc <SiDotnet/></li>
                  <li>React js <FaReact/></li>
                  <li>Xamarin Forms / MAUI <SiXamarin/></li>
                  <li>SQL Server <SiMicrosoftsqlserver/></li>
                  <li>Cloud with Azure (App Services, Functions, SignalR, etc) <SiMicrosoftazure/></li>
                  <li>Apis <GrServices/></li>
                  <li>GitHub, GIT, TFS, Bitbucket <SiGithub/></li>
                  <li>Devops, CI/CD <SiAzuredevops/></li>
                </ul>
                <h2>Certifications</h2>
                <ul>
                  <li className="li-p">Microsoft: Azure Developer Associate <a href='https://learn.microsoft.com/api/credentials/share/en-us/LUISSANDOVAL-5751/88BA7F0EF27D4087?sharingId=4112CC77E15438D0'>(Check it here!)</a></li>
                  <li className="li-p">Microsoft: Azure AI Fundamentals <a href='https://www.credly.com/badges/ab6af0d2-9756-425c-b6fc-2589d9a0240f'>(Check it here!)</a></li>
                  <li className="li-p">Microsoft: Azure Fundamentals <a href='https://learn.microsoft.com/api/credentials/share/en-us/LUISSANDOVAL-5751/6E18A2881646833A?sharingId=4112CC77E15438D0'>(Check it here!)</a></li>
                </ul>
              </div>
            </PostFullContent>
          </article>
        </div>
      </main>
      <Footer />
    </Wrapper>
  </IndexLayout>
);

export default About;